import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client"
import React from "react"
import { ApolloProvider } from "react-apollo"

//import ApolloClient from 'apollo-boost';
import fetch from "isomorphic-fetch"

// custom typefaces
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import "typeface-merriweather"
import "typeface-montserrat"

// normalize CSS across browsers
import "./src/css/normalize.css"

import "./src/css/style.css"

const link = createHttpLink({
  fetch,
  uri: "https://admin.aestheticprofessionals.co.uk/?graphql",
})
const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
})

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={apolloClient}>{element}</ApolloProvider>
)
